@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: myFont;
  src: url(/static/media/TiemposHeadline-Regular.20e0ab08.otf);
}

@font-face {
  font-family: InterExtraBold;
  src: url(/static/media/Inter-ExtraBold.568245cc.woff);
}
@font-face {
  font-family: InterSemiBold;
  src: url(/static/media/Inter-SemiBold.8a12c702.woff);
}

@font-face {
  font-family: InterBold;
  src: url(/static/media/Inter-Bold.895ddea9.woff);
}

html {
  scroll-behavior: smooth;
}

.app {
  background-color: white;
}

.hero {
  display: flex;
  flex: 1 1;
  border: 1px solid black;
  padding-left: 90px;
  padding-top: 200px;

  /* Parallax effect  */
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}

.hero_content {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.hero_name {
  font-family: "myFont";
  font-weight: normal;
  font-size: 72px;
  color: white;
  margin: 0;
  -webkit-animation: fadein 2s;
  animation: fadein 2s;
}

.hero_jobTitle {
  color: white;
  font-weight: bold;
  font-family: "InterBold";
  font-size: 36px;
  margin: 0;
  -webkit-animation: fadein 3s;
  animation: fadein 3s;
}

.hero_company {
  color: #5172ff;
  font-family: "InterBold";
  font-size: 36px;
  margin: 0;
  -webkit-animation: fadein 3s;
  animation: fadein 3s;
}

@media only screen and (max-width: 600px) {
  .hero {
    height: 550px;
    padding-left: 50px;
  }

  .hero_name {
    font-size: 42px;
    font-weight: normal;
  }

  .hero_jobTitle {
    font-size: 24px;
  }

  .hero_company {
    font-size: 18px;
  }
}

@media only screen and (max-width: 400px) {
  .hero {
    height: 450px;
    padding-left: 20px;
    padding-top: 200px;
  }

  .hero_name {
    font-size: 32px;
    font-weight: normal;
  }

  .hero_jobTitle {
    font-size: 18px;
  }

  .hero_company {
    font-size: 18px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

/* Opera < 12.1 */

.header {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  height: 70px;
  background: transparent;
  padding-left: 100px;
  padding-right: 100px;
}

.header-content {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 300px;
  /* background: red; */
}

.header-content a {
  font-family: "InterExtraBold";
  font-size: 20px;
  color: white;
  cursor: pointer;
  letter-spacing: 2px;
  text-decoration: none;
}

.bio {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
  margin-top: -150px;
  /* border: 3px solid blue; */
}

.bio-content {
  display: flex;
  width: 75%;
  height: 100%;
  background: black;
  padding-top: 60px;
  padding-left: 40px;
  padding-bottom: 30px;
  /* border: 3px solid pink; */
}

.bio-skill {
  display: flex;
  flex-direction: column;
}

.bio-skill_box {
  color: #989898;
  font-size: 24px;
  width: 230px;
  -webkit-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
}

.bio-content_text {
  display: flex;
  flex-direction: column;
  color: white;
  font-weight: bold;
  font-size: 28px;
  flex: 1 1;
  padding-left: 20px;
  padding-right: 20px;
  /* border: 1px solid white; */
}

.bio-content_text p {
  margin: 0;
}

.bio-content_text__subtext {
  color: #989898;
  font-size: 24px;
}

@media only screen and (max-width: 850px) {
  .bio-content {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 0;
  }

  .bio-content_text {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .bio-content_text__subtext,
  .bio-skill_box {
    font-size: 14px;
  }
}

@media only screen and (max-width: 400px) {
  .bio-content {
    width: 85%;
  }
}

.work {
  display: flex;
  flex-direction: column;
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 50px;
}

.work-heading {
  font-family: "myFont";
  font-size: 72px;
  font-weight: normal;
  height: 72px;
  width: 180px;
  border-bottom-style: solid;
  border-bottom: 6px solid #5172ff;
  padding-bottom: 3px;
}

.work-job {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
  /* height: 300px; */
}

.work-job_container {
  /* border: 1px solid pink; */
  max-width: 50%;
}

.work-job_title {
  margin-top: 0;
  font-family: "InterExtraBold";
  letter-spacing: 3px;
  font-size: 30px;
}

.work-job_arrow {
  margin-left: 20px;
}

.work-job_description {
  font-family: "InterSemiBold";
  font-size: 24px;
  line-height: 1.5;
}

.work-job_link {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: normal;
  margin-right: 20px;
  text-decoration: none;
  color: inherit;
  -webkit-transition: font-size 0.5s, color 0.3s;
  transition: font-size 0.5s, color 0.3s;
}

.work-job_link:hover {
  color: #5172ff;
  font-size: 25px;
  -webkit-transition: font-size 0.5s, color 0.3s;
  transition: font-size 0.5s, color 0.3s;
}

.work-job_employ {
  height: 328px;
  width: 525px;
  box-shadow: none;
  -webkit-transition: box-shadow 0.3s; /* Safari prior 6.1 */
  transition: box-shadow 0.3s;
}

.work-job_karma {
  height: 255px;
  width: 525px;
  box-shadow: none;
  border: 1px solid rgb(245, 245, 245);
  -webkit-transition: box-shadow 0.3s; /* Safari prior 6.1 */
  transition: box-shadow 0.3s;
}

.work-job_jumbleberry {
  height: 328px;
  width: 525px;
  box-shadow: none;
  border: 1px solid rgb(245, 245, 245);
  -webkit-transition: box-shadow 0.3s; /* Safari prior 6.1 */
  transition: box-shadow 0.3s;
}

.work-job_employ:hover,
.work-job_karma:hover,
.work-job_jumbleberry:hover {
  -webkit-transition: box-shadow 0.3s; /* Safari prior 6.1 */
  transition: box-shadow 0.3s;
  box-shadow: 0 10px 20px rgba(235, 235, 235, 0.19),
    0 6px 6px rgba(194, 194, 194, 0.23);
}

@media only screen and (max-width: 1050px) {
  .work {
    padding-top: 80px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .work-job {
    margin-bottom: 200px;
  }

  .work-job_employ {
    height: calc(328px * 0.8);
    width: calc(525px * 0.8);
  }

  .work-job_jumbleberry {
    height: calc(328px * 0.8);
    width: calc(525px * 0.8);
  }

  .work-job_karma {
    height: calc(255px * 0.8);
    width: calc(525px * 0.8);
  }
}

@media only screen and (max-width: 850px) {
  .work {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
  }

  .work-heading {
    height: 42px;
    width: 100px;
    font-size: 36px;
  }

  .work-job {
    display: flex;
    height: auto;
    flex-direction: column;
    margin-bottom: 100px;
  }

  .work-job_description {
    font-size: 18px;
  }

  .work-job_container {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .work-job_title {
    font-size: 24px;
  }

  .work-job_jumbleberry {
    height: auto;
    width: 100%;
  }
  .work-job_employ {
    height: auto;
    width: 100%;
  }

  .work-job_karma {
    height: auto;
    width: 100%;
    /* border: 1px solid rgb(245, 245, 245); */
  }

  .work-job_link {
    display: none;
  }

  .work-job_arrow {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .work {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
  }

  .work-heading {
    height: 42px;
    width: 100px;
    font-size: 36px;
  }

  .work-job {
    display: flex;
    height: auto;
    flex-direction: column;
    margin-bottom: 100px;
  }

  .work-job_description {
    font-size: 14px;
  }

  .work-job_container {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .work-job_title {
    font-size: 18px;
  }

  .work-job_jumbleberry {
    height: auto;
    width: 100%;
  }
  .work-job_employ {
    height: auto;
    width: 100%;
  }

  .work-job_karma {
    height: auto;
    width: 100%;
    /* border: 1px solid rgb(245, 245, 245); */
  }

  .work-job_link {
    display: none;
  }

  .work-job_arrow {
    display: none;
  }
}

.icons {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 50px;
  color: #989898;
  height: 100px;
  cursor: pointer;
}

.icons_container {
  display: flex;
  justify-content: space-around;
  width: 200px;
}

.footer {
  display: flex;
  position: relative;
  flex: 1 1;
  justify-content: space-between;
  background: #000000;
  padding-left: 90px;
  padding-right: 90px;
  padding-bottom: 90px;
}

.footer_textContainer {
  max-width: 900px;
  margin-right: 50px;
}

.footer_heading {
  color: white;
  font-family: "myFont";
  font-size: 54px;
}

.footer_blurb {
  color: #989898;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
}

.footer_image {
  min-height: 430px;
  min-width: 430px;
  height: 430px;
  width: 430px;
  margin-top: -100px;
  background-position: 50% 50%;
  background-size: cover;
}

@media only screen and (max-width: 1050px) {
  .footer_image {
    min-height: 300px;
    min-width: 300px;
    height: 300px;
    width: 300px;
    margin-top: -100px;
  }
}

@media only screen and (max-width: 850px) {
  .footer {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer_textContainer {
    padding-top: 80px;
    margin-top: 50px;
  }

  .footer_heading {
    color: white;
    font-family: "myFont";
    font-size: 27px;
  }

  .footer_blurb {
    color: #989898;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
  }

  .footer_image {
    position: absolute;
    right: 20px;
    min-height: 203px;
    min-width: 203px;
    height: 203px;
    width: 203px;
    background-position: 50% 50%;
    background-size: cover;
    margin-top: -100px;
  }
}

