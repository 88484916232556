.footer {
  display: flex;
  position: relative;
  flex: 1;
  justify-content: space-between;
  background: #000000;
  padding-left: 90px;
  padding-right: 90px;
  padding-bottom: 90px;
}

.footer_textContainer {
  max-width: 900px;
  margin-right: 50px;
}

.footer_heading {
  color: white;
  font-family: "myFont";
  font-size: 54px;
}

.footer_blurb {
  color: #989898;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
}

.footer_image {
  min-height: 430px;
  min-width: 430px;
  height: 430px;
  width: 430px;
  margin-top: -100px;
  background-position: 50% 50%;
  background-size: cover;
}

@media only screen and (max-width: 1050px) {
  .footer_image {
    min-height: 300px;
    min-width: 300px;
    height: 300px;
    width: 300px;
    margin-top: -100px;
  }
}

@media only screen and (max-width: 850px) {
  .footer {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer_textContainer {
    padding-top: 80px;
    margin-top: 50px;
  }

  .footer_heading {
    color: white;
    font-family: "myFont";
    font-size: 27px;
  }

  .footer_blurb {
    color: #989898;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
  }

  .footer_image {
    position: absolute;
    right: 20px;
    min-height: 203px;
    min-width: 203px;
    height: 203px;
    width: 203px;
    background-position: 50% 50%;
    background-size: cover;
    margin-top: -100px;
  }
}
