.hero {
  display: flex;
  flex: 1;
  border: 1px solid black;
  padding-left: 90px;
  padding-top: 200px;

  /* Parallax effect  */
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}

.hero_content {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.hero_name {
  font-family: "myFont";
  font-weight: normal;
  font-size: 72px;
  color: white;
  margin: 0;
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

.hero_jobTitle {
  color: white;
  font-weight: bold;
  font-family: "InterBold";
  font-size: 36px;
  margin: 0;
  -webkit-animation: fadein 3s;
  -moz-animation: fadein 3s;
  -ms-animation: fadein 3s;
  -o-animation: fadein 3s;
  animation: fadein 3s;
}

.hero_company {
  color: #5172ff;
  font-family: "InterBold";
  font-size: 36px;
  margin: 0;
  -webkit-animation: fadein 3s;
  -moz-animation: fadein 3s;
  -ms-animation: fadein 3s;
  -o-animation: fadein 3s;
  animation: fadein 3s;
}

@media only screen and (max-width: 600px) {
  .hero {
    height: 550px;
    padding-left: 50px;
  }

  .hero_name {
    font-size: 42px;
    font-weight: normal;
  }

  .hero_jobTitle {
    font-size: 24px;
  }

  .hero_company {
    font-size: 18px;
  }
}

@media only screen and (max-width: 400px) {
  .hero {
    height: 450px;
    padding-left: 20px;
    padding-top: 200px;
  }

  .hero_name {
    font-size: 32px;
    font-weight: normal;
  }

  .hero_jobTitle {
    font-size: 18px;
  }

  .hero_company {
    font-size: 18px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
