@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

.work {
  display: flex;
  flex-direction: column;
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 50px;
}

.work-heading {
  font-family: "myFont";
  font-size: 72px;
  font-weight: normal;
  height: 72px;
  width: 180px;
  border-bottom-style: solid;
  border-bottom: 6px solid #5172ff;
  padding-bottom: 3px;
}

.work-job {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
  /* height: 300px; */
}

.work-job_container {
  /* border: 1px solid pink; */
  max-width: 50%;
}

.work-job_title {
  margin-top: 0;
  font-family: "InterExtraBold";
  letter-spacing: 3px;
  font-size: 30px;
}

.work-job_arrow {
  margin-left: 20px;
}

.work-job_description {
  font-family: "InterSemiBold";
  font-size: 24px;
  line-height: 1.5;
}

.work-job_link {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: normal;
  margin-right: 20px;
  text-decoration: none;
  color: inherit;
  -webkit-transition: font-size 0.5s, color 0.3s;
  transition: font-size 0.5s, color 0.3s;
}

.work-job_link:hover {
  color: #5172ff;
  font-size: 25px;
  -webkit-transition: font-size 0.5s, color 0.3s;
  transition: font-size 0.5s, color 0.3s;
}

.work-job_employ {
  height: 328px;
  width: 525px;
  box-shadow: none;
  -webkit-transition: box-shadow 0.3s; /* Safari prior 6.1 */
  transition: box-shadow 0.3s;
}

.work-job_karma {
  height: 255px;
  width: 525px;
  box-shadow: none;
  border: 1px solid rgb(245, 245, 245);
  -webkit-transition: box-shadow 0.3s; /* Safari prior 6.1 */
  transition: box-shadow 0.3s;
}

.work-job_jumbleberry {
  height: 328px;
  width: 525px;
  box-shadow: none;
  border: 1px solid rgb(245, 245, 245);
  -webkit-transition: box-shadow 0.3s; /* Safari prior 6.1 */
  transition: box-shadow 0.3s;
}

.work-job_employ:hover,
.work-job_karma:hover,
.work-job_jumbleberry:hover {
  -webkit-transition: box-shadow 0.3s; /* Safari prior 6.1 */
  transition: box-shadow 0.3s;
  box-shadow: 0 10px 20px rgba(235, 235, 235, 0.19),
    0 6px 6px rgba(194, 194, 194, 0.23);
}

@media only screen and (max-width: 1050px) {
  .work {
    padding-top: 80px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .work-job {
    margin-bottom: 200px;
  }

  .work-job_employ {
    height: calc(328px * 0.8);
    width: calc(525px * 0.8);
  }

  .work-job_jumbleberry {
    height: calc(328px * 0.8);
    width: calc(525px * 0.8);
  }

  .work-job_karma {
    height: calc(255px * 0.8);
    width: calc(525px * 0.8);
  }
}

@media only screen and (max-width: 850px) {
  .work {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
  }

  .work-heading {
    height: 42px;
    width: 100px;
    font-size: 36px;
  }

  .work-job {
    display: flex;
    height: auto;
    flex-direction: column;
    margin-bottom: 100px;
  }

  .work-job_description {
    font-size: 18px;
  }

  .work-job_container {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .work-job_title {
    font-size: 24px;
  }

  .work-job_jumbleberry {
    height: auto;
    width: 100%;
  }
  .work-job_employ {
    height: auto;
    width: 100%;
  }

  .work-job_karma {
    height: auto;
    width: 100%;
    /* border: 1px solid rgb(245, 245, 245); */
  }

  .work-job_link {
    display: none;
  }

  .work-job_arrow {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .work {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
  }

  .work-heading {
    height: 42px;
    width: 100px;
    font-size: 36px;
  }

  .work-job {
    display: flex;
    height: auto;
    flex-direction: column;
    margin-bottom: 100px;
  }

  .work-job_description {
    font-size: 14px;
  }

  .work-job_container {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .work-job_title {
    font-size: 18px;
  }

  .work-job_jumbleberry {
    height: auto;
    width: 100%;
  }
  .work-job_employ {
    height: auto;
    width: 100%;
  }

  .work-job_karma {
    height: auto;
    width: 100%;
    /* border: 1px solid rgb(245, 245, 245); */
  }

  .work-job_link {
    display: none;
  }

  .work-job_arrow {
    display: none;
  }
}
